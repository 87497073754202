import { library, dom } from '@fortawesome/fontawesome-svg-core';

import {
	faFacebook,
	faInstagram,
	faTwitter
} from '@fortawesome/free-brands-svg-icons';

import { faBars } from '@fortawesome/pro-light-svg-icons';


library.add(
	faBars,
	faFacebook,
	faInstagram,
	faTwitter
);

dom.watch();
